import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveUserInfo(state, action) {
      return { ...action.payload };
    },
    logout(state) {
      return {};
    },
  },
});

export const { saveUserInfo, logout } = authSlice.actions;
export default authSlice.reducer;
