import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarImage: '',
  sidebarBgColor: '',
  sidebarImageUrl: '',
  sidebarCollapsed: false,
  sidebarSize: 'sidebar-md',
}

const customizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    sidebarImage(state, action) {
      return { ...state, sidebarImage: action.payload }
    },
    sidebarBgColor(state, action) {
      return { ...state, sidebarBgColor: action.payload }
    },
    sidebarCollapsed(state, action) {
      return { ...state, sidebarCollapsed: action.payload }
    },
    sidebarImageUrl(state, action) {
      return { ...state, sidebarImageUrl: action.payload }
    },
    sidebarSize(state, action) {
      return { ...state, sidebarSize: action.payload }
    },
  },
})

export const {
  sidebarImage,
  sidebarBgColor,
  sidebarCollapsed,
  sidebarImageUrl,
  sidebarSize,
} = customizerSlice.actions
export default customizerSlice.reducer
