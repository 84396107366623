// import external modules
import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

// import internal(own) modules
import registerServiceWorker from "./registerServiceWorker";
import { store } from "./redux/storeConfig/newStore";
// import ReduxToastr from 'react-redux-toastr'
import { ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "font-awesome/css/font-awesome.min.css";

import "./index.scss";
import Spinner from "./components/spinner/spinner";


const LazyApp = lazy(() => import("./app/app"));

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
   <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <LazyApp />
        {/* <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-left"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick/> */}
            <ToastContainer/>
      </Suspense>
   </Provider>
);
registerServiceWorker();
