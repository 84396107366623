import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import customizerReducer from './customizerSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  customizer: customizerReducer
  // Add more slice reducers here if you have additional slices
});

export default rootReducer;